import React from 'react';
import './Profile.css';
import Skills from './Skills';
import Projects from './Projects/Projects.js';
import Contact from './Contact/Contact.js';

// import { Follow } from 'react-twitter-widgets';
// import DonateButton from '../PayPalDonateButton/PayPalDonateButton.js';
import profileImage1 from './images/william_mollenkopf1.jpg';
import profileImage2 from './images/william_mollenkopf2.jpg';

// Import quotes from a JSON file
import quotes from './quotes.json';  // Adjust the path to where your quotes.json is located

// Function to randomly pick a quote
const getRandomQuote = () => {
	const randomIndex = Math.floor(Math.random() * quotes.length);
	return quotes[randomIndex];
  };

const min = 1;
const max = 2;
const rand = min + Math.round(Math.random())*(max-min);

const Profile = ({backgroundClass="background-snowwhite"}) => {
	const randomQuote = getRandomQuote();  // Get the random quote here
	return (
		<div className={backgroundClass}>
			<div id="profile" className="profileContainer container">
				<h2>About Me</h2>
				<blockquote className="blockquote lead">
					<p className="mb-0">&ldquo;{randomQuote.quote}&rdquo;</p>
					<footer className="blockquote-footer">{randomQuote.author}</footer>
				</blockquote>
				<hr />
				<Contact />
				<div className="row">
					<div className="col-md-4">
						<h3 className="text-center">Introduction</h3>
						<p>Hello! I'm currently a Systems Administrator with over 6 years of experience, and an additional 10 years as a Developer. I'm passionate about continuous learning and growth, and currently studying for Azure Certifications.</p>
					</div>
					<div className="col-md-4 text-center">
						<img className="profileImage" src={(rand>1)?profileImage2:profileImage1} alt="William Mollenkopf" />
					</div>
					<div className="col-md-4">
					<h3 className="text-center">Hobbies & Interests</h3>
					<p>In my free time I enjoy playing video games, playing Chess, learning foreign languages, traveling around the world with my wife and reading. As well as doing some light personal web development and home server projects for fun.</p>
					</div>
				</div>
				
				{/* <hr className="hr-mini" /> */}
				<Skills />
				{/* <hr className="hr-mini" />
				<Projects /> */}
			</div>
		</div>
	);
};

export default Profile;