import React from 'react';

const Skills = () => {
    return (
	<div>
		<h3 className="text-center">Skills</h3>
		<div className="row">
			<div className="col-md-6 text-right border-right ">
				<h4 className="">OPERATING SYSTEMS</h4>
				<span className="skillsubtitle">Microsoft:</span>
				<p className="skillitem-push-right text-right">Active Directory, LDAP, Group Policy, DNS</p>
				<span className="skillsubtitle">Linux: </span>
				<p className="skillitem-push-right text-right">Ubuntu, Red Hat, Arch</p>
				<span className="skillsubtitle">OpenVMS: </span>
				<p className="skillitem-push-right text-right">Clustering, Load Balancing, Samba, DCL, E-Mail, DNS, DHCP</p>
			</div>
			
			<div className="col-md-6 border-left ">
			<h4 className="">VIRTUALIZATION</h4>
				<span className="skillsubtitle">VMWare: </span>
				<p className="skillitem-push-left text-left">ESXi, vCenter, vSphere</p>
				<span className="skillsubtitle">Azure: </span>
				<p className="skillitem-push-left text-left">Compute, Storage, Networking, IAM, Monitoring, Automation, Governance, Backup</p>
				<span className="skillsubtitle">Others: </span>
				<p className="skillitem-push-left text-left">ProxMox, OpenStack</p>
			</div>
		</div>
		<div className="row">
			<div className="col-md-6 text-right border-right ">
				<h4 className="">SPOKEN & WRITTEN</h4>
					<span className="skillsubtitle">Native fluency:</span>
						<p className="skillitem-push-right text-right">English</p>
					<span className="skillsubtitle">Basic proficiency:</span>
						<p className="skillitem-push-right text-right">Japanese, Indonesian</p>
			</div>
			<div className="col-md-6 border-left ">
				<h4 className="">PROGRAMMING & SCRIPTING</h4>
				<span className="skillsubtitle">Languages: </span>
					<p className="skillitem-push-left text-left">PHP, JavaScript, Python, CSS, HTML, XML, MySQL, Oracle PL/SQL, C/C++, Fortran, Bash, PowerShell, Many Others...</p>

			</div>
		</div>

    </div>);
}

export default Skills;